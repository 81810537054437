import { User } from '~/models'
import { $axios } from '~/utils/api'

const USERS_ENDPOINT = '/users'

const toggleEmailNotifications = (formData: any): Promise<User> =>
  $axios.$patch(`${USERS_ENDPOINT}/email_notifications`, formData)

const getCurrentUser = (): Promise<User> =>
  $axios.$get(`${USERS_ENDPOINT}/get_current`)

const setNotificationTime = (notification_datetime: any) =>
  $axios.$patch(`${USERS_ENDPOINT}/notification`, notification_datetime)

const getUsers = (params: any = {}): Promise<User[]> => {
  if (Object.keys(params).length > 0) {
    return $axios.$get(USERS_ENDPOINT, { params })
  }
  return $axios.$get(USERS_ENDPOINT)
}

const createUser = (data: any): Promise<User> =>
  $axios.$post(USERS_ENDPOINT, data)

const updateUser = (id: number, data: any): Promise<User> =>
  $axios.$patch(`${USERS_ENDPOINT}/${id}`, data)

const deleteUser = (id: number): Promise<void> =>
  $axios.$delete(`${USERS_ENDPOINT}/${id}`)

export {
  toggleEmailNotifications,
  getCurrentUser,
  setNotificationTime,
  getUsers,
  createUser,
  updateUser,
  deleteUser,
}
